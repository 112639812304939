import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

export default function Navbar(props){
    return(
        <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}>
    <div className="container-fluid">
    <Link className="navbar-brand" to="/">{props.title}</Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">{props.aboutText}</Link>
        </li>
      </ul>
      <form className="d-flex" role="search">
        {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/> */}
        {/* <button className="btn btn-outline-primary" type="submit">Search</button> */}
        <div className={`form-check form-switch text-${props.mode==='light'?'dark':
          'light'
        } form-check-reverse`}>
          <input className="form-check-input" type="checkbox" id="flexSwitchCheckReverse" onClick={props.toggleMode}/>
          <label className="form-check-label" htmlFor="flexSwitchCheckReverse">Enable Dark Mode</label>
        </div>
      </form>
    </div>
    </div>
    </nav>
    )
}

Navbar.propTypes = {title: PropTypes.string,
                    aboutText: PropTypes.string
}

//Navbar.defaultProps = {
//    title: 'Set title here',
//    aboutText: 'About text here'
//};
