import React, {useState} from 'react'

export default function Textform(props){
    
    const[text, setText] = useState("");

    const handleUpClick = ()=>{
        // console.log("Uppercase was clicked");
        let newText = text.toUpperCase();
        setText(newText);
        props.showAlert("Converted to Uppercase!", "success");
    }

    const handleLoClick = ()=>{
        // console.log("Lowercase was clicked");
        let newText = text.toLowerCase();
        setText(newText);
        props.showAlert("Converted to Lowercase!", "success");
    }

    const handleClear = ()=>{
        // console.log("Clear was clicked");
        let newText = '';
        setText(newText);
        props.showAlert("Text area cleared!", "success");
    }

    const handleTitleClick = ()=>{
        // console.log("Titlecase was clicked");
        let newText = text.toLowerCase().split(' ').map(function(word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
          }).join(' ');
        setText(newText);
        props.showAlert("Converted to Titlecase!", "success");
    }

    const handleSentenceClick = ()=>{
        // console.log("Sentencecase was clicked");
        let newText = text.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g,function(c){return c.toUpperCase()});
        setText(newText);
        props.showAlert("Converted to Sentencecase!", "success");
    }

    // const handleCapitalizeClick = ()=>{
    //     // console.log("Capitalizecase was clicked");
        
    //     let newText = text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    //     setText(newText);
    //     props.showAlert("Capitalized!", "success");
    // }

    const handleInverseClick = ()=>{
        // console.log("Inversecase was clicked");
        
        let newText = text.split("").map(item => item === item.toUpperCase()? item.toLowerCase(): item.toUpperCase()).join('');
        setText(newText);
        props.showAlert("Inversed!", "success");
    }

    const handleCopyClick = ()=>{
        // var text = document.getElementById("myBox");
        // text.select();
        navigator.clipboard.writeText(text);
        document.getSelection().removeAllRanges();
        props.showAlert("Text copied!", "success");
    }

    const handleExtraSpaces = () => {
        let newText = text.split(/[ ] + /);
        setText(newText.join(" "));
        props.showAlert("Extraspaces removed!", "success");
    }

    const handleOnChange = (event)=>{
        // console.log("On Change");
        setText(event.target.value);
    }

    //setText("Enter text");
    return(
        <>
        <div className = "container" >
            <h1 style = {{color: props.mode==='dark'?'white':'black'}}>{props.heading} </h1>
            <div className="mb-3">
            <textarea className="form-control" value={text} onChange ={handleOnChange} style = {{backgroundColor: props.mode==='dark'?'darkgrey':
          'white', color: props.mode==='dark'?'white':'black'}} id="myBox" rows="8"></textarea>
            </div>
            <button disabled ={text.length===0} className = "btn btn-primary mx-1 my-1" onClick={handleUpClick}>Convert to Uppercase</button>
            <button disabled ={text.length===0} className = "btn btn-primary mx-1 my-1" onClick={handleLoClick}>Convert to Lowercase</button>
            <button disabled ={text.length===0} className = "btn btn-primary mx-1 my-1" onClick={handleTitleClick}>Title Case</button>
            <button disabled ={text.length===0} className = "btn btn-primary mx-1 my-1" onClick={handleSentenceClick}>Sentence Case</button>
            {/* <button className = "btn btn-primary mx-1 my-1" onClick={handleCapitalizeClick}>Capitalize Case</button> */}
            <button disabled ={text.length===0} className = "btn btn-primary mx-1 my-1" onClick={handleInverseClick}>Inverse Case</button>
            <button disabled ={text.length===0} className = "btn btn-primary mx-1 my-1" onClick={handleExtraSpaces}>Remove Extra Spaces</button>
            <button disabled ={text.length===0} className = "btn btn-primary mx-1 my-1" onClick={handleCopyClick}>Copy Text</button>
            <button disabled ={text.length===0} className = "btn btn-danger mx-1 my-1" onClick={handleClear}>Clear</button>
        </div>
        <div className = "container my-3" style = {{color: props.mode==='dark'?'white':'black'}}>
            <h2> Your text summary</h2>
            <p>{text.split(/\s+/).filter((element)=>{return element.length!==0}).length} words and {text.length} characters</p>
            <p>{0.008 * text.split(" ").filter((element)=>{return element.length!==0}).length} minutes to read</p>
            <h2>Preview</h2>
            <p>{text.length>0?text:"Nothing to preview!"}</p>
        </div>
        </>
    )
}