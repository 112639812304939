import React from 'react'

export default function About(props){

    // const [myStyle, setMyStyle] = useState({
    //     color: 'black',
    //     backgroundColor: 'white'
    // })

    let myStyle = {
        color: props.mode === 'dark'?'white':'#343a40',
        backgroundColor: props.mode === 'dark'?'#343a40':'white',
        borderColor: props.mode === 'dark'?'white':'#343a40'
    }

    // const [btntxt, setBtnText] = useState("Enable Dark Mode")

    // const handleToggleClick = ()=>{
        
    //     if(myStyle.color === 'black'){
    //         // console.log("Dark Mode was clicked");
    //         setMyStyle({
    //             color: 'white',
    //             backgroundColor: 'black',
    //         })
    //         setBtnText("Enable Light Mode");
    //     }
    //     else{
    //         // console.log("Light Mode was clicked");
    //         setMyStyle({
    //             color: 'black',
    //             backgroundColor: 'white'
    //         })
    //         setBtnText("Enable Dark Mode");
    //     }
    // }
    return (
        <div className = "container" style = {myStyle}>
           <div className="accordion" id="accordionExample" style = {myStyle}>
            <h1>About us </h1>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" style = {myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Analyze your text
                        </button>
                    </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div className="accordion-body" style = {myStyle}>
                    Textutils gives you a way to analyze your text quickly and efficiently. Be it word count or character count
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header">
                <button className="accordion-button collapsed" style = {myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Free to use
                </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body" style = {myStyle}>
                TextUtils is a free character counter tool that provides instant character count & word statistics for a given text. It reports the number of words and characters. Thus it is suitable for writing text with word/character limit. 
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button className="accordion-button collapsed" style = {myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Browser Compatible
                    </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div className="accordion-body" style = {myStyle}>
                        This word counter works in any web browser!
                    </div>
                </div>
            </div>
        </div> 
        {/* <div className = "container my-3">
            <button className="btn btn-primary" type="submit" onClick={handleToggleClick}>{btntxt}</button>
        </div> */}
        </div>
    )
}